import apiClient from "./apiService";
import { School, SchoolQueryParams } from "../interfaces/School";

export default {
  async getAllSchools(
    params?: SchoolQueryParams
  ): Promise<ApiResponse<School[]>> {
    const response = await apiClient.get<ApiResponse<School[]>>("/schools", {
      params,
    });
    return response.data;
  },

  async migrateSchoolReports(school_id: number): Promise<ApiResponse<School>> {
    const response = await apiClient.post<ApiResponse<School>>(
      "/schools/migrate/reports",
      { school_id }
    );
    return response.data;
  },

  async exportSchoolReports(school_id: number): Promise<ApiResponse<any>> {
    const response = await apiClient.post<ApiResponse<School>>(
      "/schools/generate/exams/report",
      { school_id }
    );
    return response.data;
  },

  async getSchools(params?: {
    name?: string;
    round?: number;
    status?: number;
    page?: number;
  }) {
    const response = await apiClient.get("/schools/status", { params });
    return response.data;
  },

  async generateReport(schoolIds: number[]): Promise<void> {
    const formData = new FormData();
    schoolIds.forEach((id) =>
      formData.append("correction_status_ids[]", id.toString())
    );

    await apiClient.post("/schools/reports", formData, {
      headers: { Accept: "application/json" },
      withCredentials: true,
    });
  },

  async generateGradeReport(gradeIds: number[]): Promise<void> {
    const formData = new FormData();
    gradeIds.forEach((id) =>
      formData.append("correction_status_ids[]", id.toString())
    );

    await apiClient.post("/grades/reports", formData, {
      headers: { Accept: "application/json" },
      withCredentials: true,
    });
  },

  //reports listing
  async getReports(params?: {
    name?: string;
    round?: number;
    status?: number;
    page?: number;
  }) {
    const response = await apiClient.get("/reports", { params });
    return response.data;
  },
};
