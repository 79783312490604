import { Module } from "vuex";
import reportsListingService from "@/services/reportsListingService";
import { Report, ReportQueryParams } from "@/interfaces/Report";
import { Platform } from "@/interfaces/Platform";
import extractFilters from "../extractFilters";
interface ReportsState {
  reports: Report[];
  filters: object;
  selectedPlatform: Platform | null;
  loading: boolean;
  loadingReportId: number | null;
  downloadingReport: boolean;
  downloadReportsError: boolean;
  params: ReportQueryParams; // Query parameters for API
  meta: PaginationMeta; // Pagination metadata
  error: string | null; // Error handling
}

const reportsListingModule: Module<ReportsState, any> = {
  namespaced: true,

  // State: Add flexibility to the params
  state: (): ReportsState => ({
    reports: [],
    filters: {},
    selectedPlatform: null,
    loading: false,
    loadingReportId: null,
    downloadingReport: false,
    downloadReportsError: false,
    params: {
      name: "", // Search query
      round: undefined, // Round filter
      status: undefined, // Status filter
      sort: "id",
      order: "asc",
      page: 1,
    },
    meta: {
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
      first_page_number: 1,
      last_page_number: 0,
      next_page_number: null,
      prev_page_number: null,
      pages: [],
    },
    error: null,
  }),

  // Mutations
  mutations: {
    setReports(state, reports: Report[]) {
      state.reports = reports;
    },
    setLoading(state, loading: boolean) {
      state.loading = loading;
    },
    setLoadingReport(state, reportId: number | null) {
      state.loadingReportId = reportId;
    },
    setDownloadingReport(state, downloading: boolean) {
      state.downloadingReport = downloading;
    },
    setDownloadReportsError(state, hasError: boolean) {
      state.downloadReportsError = hasError;
    },
    setParams(state, params: Partial<ReportsState["params"]>) {
      state.params = { ...state.params, ...params };
    },
    setFilters(state, filters) {
      state.filters = filters;
    },
    setPaginationMeta(state, meta: PaginationMeta) {
      state.meta = meta;
    },
    setError(state, error: string | null) {
      state.error = error;
    },
  },

  // Actions
  actions: {
    async fetchReportsListing({ commit, state }) {
      commit("setLoading", true);
      try {
        const response = await reportsListingService.getReportsListing(
          state.params
        );

        // Extract filters
        const filters = extractFilters(response.filters);

        commit("setReports", response.data);
        commit("setFilters", filters);
        commit("setPaginationMeta", response.meta);
      } catch (error) {
        commit("setError", error || "Failed to fetch reports");
      } finally {
        commit("setLoading", false);
      }
    },

    async downloadReports({ commit }, payload) {
      commit("setDownloadingReport", true);
      commit("setDownloadReportsError", false);
      try {
        // Make the API request
        const response = await reportsListingService.downloadReports(payload);

        if (!response || !response.data) {
          throw new Error("Invalid response from the server.");
        }

        // Convert response to Blob
        const isMultipleReports = payload.reportIds.length > 1;
        const blob = new Blob([response.data], {
          type: isMultipleReports ? "application/zip" : "application/pdf",
        });

        // Use `fileName` if only one report is downloaded
        let filename = isMultipleReports
          ? "Schools_Reports.zip"
          : payload.fileName || "School_Report.pdf";

        // Create a download link
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading reports:", error);
        commit("setDownloadReportsError", true); // trigger toast
      } finally {
        commit("setDownloadingReport", false);
      }
    },

    updateParams({ commit, dispatch }, newParams) {
      commit("setParams", newParams);
      dispatch("fetchReportsListing");
    },
  },

  // Getters: Add support for accessing new states
  getters: {
    allReports: (state) => state.reports,
    isLoading: (state) => state.loading,
    isDownloading: (state) => state.downloadingReport,
    downloadReportsError: (state) => state.downloadReportsError,
    hasError: (state) => !!state.error,
    errorMessage: (state) => state.error,
    loadingReportId: (state) => state.loadingReportId,
    params: (state) => state.params, // Expose params for debugging
  },
};

export default reportsListingModule;
