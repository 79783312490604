import { computed } from "vue";
import store from "@/store";

// Helper function to convert plural to singular (basic implementation)
function singularize(entity: string): string {
  if (entity.includes("-")) {
    entity = entity.replaceAll("-", ".");
  }

  if (entity.endsWith("s")) {
    return entity.slice(0, -1);
  }

  return entity;
}

export function usePermissions(entity: string) {
  const entityName = entity.replaceAll("-", ".");
  const singularEntity = singularize(entity);

  const permissions = store.getters["currentUser/currentUser"]?.permissions.map(
    (p: any) => p.permission
  );

  const canAdd = computed(() => {
    return permissions?.includes(
      `com.ltm.permissions.${entityName}.create.${singularEntity}`
    );
  });

  const canEdit = computed(() => {
    return permissions?.includes(
      `com.ltm.permissions.${entityName}.edit.${singularEntity}`
    );
  });

  const canDelete = computed(() => {
    return permissions?.includes(
      `com.ltm.permissions.${entityName}.delete.${singularEntity}`
    );
  });

  const canView = computed(() => {
    return permissions?.includes(
      `com.ltm.permissions.${entityName}.view.${singularEntity}`
    );
  });

  const canGenerate = computed(() => {
    return permissions?.includes(
      `com.ltm.permissions.${entityName}.generate.${singularEntity}`
    );
  });
  // mainly for reports because it is different
  const canViewAll = computed(() => {
    return permissions?.includes(
      `com.ltm.permissions.${entityName}.view.${entityName}`
    );
  });
  const canDownloadAll = computed(() => {
    return permissions?.includes(
      `com.ltm.permissions.${entityName}.download.${entityName}`
    );
  });

  const canSeeExamActions = (actions: string[]) =>
    computed(() =>
      permissions?.some((p: string) =>
        actions.some(
          (action) => p === `com.ltm.permissions.${entityName}.${action}`
        )
      )
    );

  return {
    canAdd,
    canEdit,
    canDelete,
    canView,
    canViewAll,
    canDownloadAll,
    canGenerate,
    canSeeExamActions,
  };
}
