import { Module } from 'vuex';
import { Platform } from '@/interfaces/Platform';
import platformsService from '@/services/platformsService';

interface PlatformsState {
    platforms: Platform[];
    selectedPlatform: Platform | null;
    isLoading: boolean;
    hasError: boolean;
    isCached: boolean;
    selectedPlatformId: number | null; // Cache selected platform ID
}

// Get a language-specific key
function getLocalizedKey(baseKey: string) {
    const currentLanguage = localStorage.getItem('language') ?? 'en';
    return `${baseKey}_${currentLanguage}`;
}

// Save to sessionStorage with language context
function saveToSessionStorage(key: string, data: any) {
    const localizedKey = getLocalizedKey(key);
    sessionStorage.setItem(localizedKey, JSON.stringify(data));
}

// Load from sessionStorage with language context
function loadFromSessionStorage(key: string) {
    const localizedKey = getLocalizedKey(key);
    const data = sessionStorage.getItem(localizedKey);
    return data ? JSON.parse(data) : null;
}

const platformsModule: Module<PlatformsState, any> = {
    namespaced: true,
    state: {
        platforms: loadFromSessionStorage('platforms') || [],
        selectedPlatform: loadFromSessionStorage('selectedPlatform') || null,
        isLoading: false,
        hasError: false,
        isCached: !!loadFromSessionStorage('platforms'),
        selectedPlatformId: loadFromSessionStorage('selectedPlatform')?.id || null,
    },
    mutations: {
        setPlatforms(state, platforms: Platform[]) {
            state.platforms = platforms;
            state.isCached = true;
            saveToSessionStorage('platforms', platforms);
        },
        setSelectedPlatform(state, platform: Platform) {
            state.selectedPlatform = platform;
            state.selectedPlatformId = platform.id;
            saveToSessionStorage('selectedPlatform', platform);
        },
        setLoading(state, isLoading: boolean) {
            state.isLoading = isLoading;
        },
        setError(state, hasError: boolean) {
            state.hasError = hasError;
        },
        clearCache(state) {
            state.isCached = false;
            state.platforms = [];
            state.selectedPlatform = null;
            state.selectedPlatformId = null;
            sessionStorage.removeItem('platforms');
            sessionStorage.removeItem('selectedPlatform');
        },
    },
    actions: {
        async fetchPlatforms({ state, commit }) {
            if (!state.isCached) {
                commit('setLoading', true);
                try {
                    const platforms = await platformsService.getAllPlatforms();
                    commit('setPlatforms', platforms);
                } catch (error) {
                    commit('setError', true);
                } finally {
                    commit('setLoading', false);
                }
            }
        },
        selectPlatform({ commit, state }, platform: Platform) {
            if (state.selectedPlatformId !== platform.id) {
                commit('setSelectedPlatform', platform);
            }
        },
        clearPlatformCache({ commit }) {
            commit('clearCache');
        },
    },
};

export default platformsModule;
