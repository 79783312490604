import apiClient from "./apiService";

export default {
  async getReportsListing(params?: {
    name?: string;
    round?: number;
    status?: number;
    page?: number;
  }) {
    const response = await apiClient.get("/reports", { params });
    return response.data;
  },

  async downloadReports({
    reportIds,
    language,
  }: {
    reportIds: number[];
    language: string;
  }) {
    const formData = new FormData();
    formData.append("language", language);

    reportIds.forEach((id: number) => {
      formData.append("report_ids[]", id.toString());
    });

    // Return full response, including headers
    return apiClient.post("/reports/download", formData, {
      responseType: "blob", // Required for file downloads
    });
  },
};
