import { Module } from "vuex";
import schoolsService from "@/services/schoolsService";
import { School, SchoolQueryParams } from "@/interfaces/School";
import { Platform } from "@/interfaces/Platform";

interface SchoolsState {
  schools: School[];
  selectedPlatform: Platform | null;
  loading: boolean;
  loadingSchoolId: number | null;
  loadingReport: boolean;
  loadingGradeReport: boolean;
  reportGenerationError: boolean;
  SchoolReportGenerationError: boolean;
  exportingSchoolId: number | null;
  params: SchoolQueryParams; // Query parameters for API
  meta: PaginationMeta; // Pagination metadata
  error: string | null; // Error handling
}

const schoolsModule: Module<SchoolsState, any> = {
  namespaced: true,

  // State: Add flexibility to the params
  state: (): SchoolsState => ({
    schools: [],
    selectedPlatform: null,
    loading: false,
    loadingSchoolId: null,
    loadingReport: false,
    loadingGradeReport: false,
    reportGenerationError: false,
    SchoolReportGenerationError: false,
    exportingSchoolId: null,
    params: {
      name: "", // Search query
      round: undefined, // Round filter
      status: undefined, // Status filter
      sort: "id",
      order: "asc",
      page: 1,
    },
    meta: {
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
      first_page_number: 1,
      last_page_number: 0,
      next_page_number: null,
      prev_page_number: null,
      pages: [],
    },
    error: null,
  }),

  // Mutations
  mutations: {
    setSchools(state, schools: School[]) {
      state.schools = schools;
    },
    setPlatform(state, platform: Platform | null) {
      state.selectedPlatform = platform;
    },
    setLoading(state, loading: boolean) {
      state.loading = loading;
    },
    setLoadingSchool(state, schoolId: number | null) {
      state.loadingSchoolId = schoolId;
    },
    setExportingSchool(state, schoolId: number | null) {
      state.exportingSchoolId = schoolId;
    },
    setLoadingReport(state, loading: boolean) {
      state.loadingReport = loading;
    },
    setLoadingGradeReport(state, loading: boolean) {
      state.loadingGradeReport = loading;
    },
    setReportGenerationError(state, hasError: boolean) {
      state.reportGenerationError = hasError;
    },
    setSchoolReportGenerationError(state, hasError: boolean) {
      state.SchoolReportGenerationError = hasError;
    },
    setParams(state, params: Partial<SchoolsState["params"]>) {
      state.params = { ...state.params, ...params };
    },
    setPaginationMeta(state, meta: PaginationMeta) {
      state.meta = meta;
    },
    setError(state, error: string | null) {
      state.error = error;
    },
  },

  // Actions
  actions: {
    // Fetch schools with the updated params
    async fetchSchools({ commit, state }) {
      commit("setLoading", true);
      try {
        const response = await schoolsService.getSchools(state.params);
        commit("setSchools", response.data);
        commit("setPaginationMeta", response.meta);
      } catch (error) {
        commit("setError", error || "Failed to fetch schools");
      } finally {
        commit("setLoading", false);
      }
    },
    // Generate School Report
    async generateSchoolReports({ commit }, selectedSchoolIds: number[]) {
      if (!selectedSchoolIds.length) return;

      commit("setLoadingReport", true);
      commit("setSchoolReportGenerationError", false);
      try {
        await schoolsService.generateReport(selectedSchoolIds);
      } catch (error) {
        commit("setSchoolReportGenerationError", true);
        console.error("Failed to generate school report:", error);
      } finally {
        commit("setLoadingReport", false);
      }
    },

    // Generate Grade Report
    async generateGradeReports({ commit }, selectedGradeIds: number[]) {
      if (!selectedGradeIds.length) return;

      commit("setLoadingGradeReport", true);
      commit("setReportGenerationError", false);
      try {
        await schoolsService.generateGradeReport(selectedGradeIds);
      } catch (error) {
        commit("setReportGenerationError", true);
        console.error("Failed to generate grade report:", error);
      } finally {
        commit("setLoadingGradeReport", false);
      }
    },

    // Update query parameters and fetch data
    updateParams(
      { commit, dispatch },
      newParams: Partial<SchoolsState["params"]>
    ) {
      commit("setParams", newParams);
      dispatch("fetchSchools");
    },

    // Platform-specific filtering
    onPlatformSelected({ commit, dispatch }, platform: Platform) {
      commit("setPlatform", platform);
      commit("setParams", { page: 1, filter: { platform_id: platform.id } });
      dispatch("fetchSchools");
    },

    // Migrate school reports
    async migrateSchoolReports({ commit, dispatch }, school_id: number) {
      commit("setLoadingSchool", school_id);
      try {
        await schoolsService.migrateSchoolReports(school_id);
      } catch (error) {
        commit("setError", error || "Failed to migrate school reports");
      } finally {
        dispatch("fetchSchools"); // Refresh the list
        commit("setLoadingSchool", null);
      }
    },

    // Export school reports
    async exportSchoolReports({ commit, dispatch }, school_id: number) {
      commit("setExportingSchool", school_id);
      try {
        const response = await schoolsService.exportSchoolReports(school_id);
        const previewUrl = response?.data?.preview_url;
        if (previewUrl) {
          // Trigger file download
          const link = document.createElement("a");
          link.href = previewUrl;
          link.download = ""; // Optionally specify a filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          commit("setError", "Failed to export school reports");
        }
      } catch (error) {
        commit("setError", error || "Failed to export school reports");
      } finally {
        commit("setExportingSchool", null);
      }
    },
  },

  // Getters: Add support for accessing new states
  getters: {
    allSchools: (state) => state.schools,
    isLoading: (state) => state.loading,
    hasError: (state) => !!state.error,
    errorMessage: (state) => state.error,
    loadingSchoolId: (state) => state.loadingSchoolId,
    exportingSchoolId: (state) => state.exportingSchoolId,
    params: (state) => state.params, // Expose params for debugging
  },
};

export default schoolsModule;
