import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/icons/audio.svg'


const _hoisted_1 = { class: "flex items-center justify-center gap-x-2" }
const _hoisted_2 = {
  key: 1,
  class: "flex items-center justify-center gap-x-2"
}
const _hoisted_3 = ["for"]
const _hoisted_4 = ["id"]
const _hoisted_5 = {
  key: 0,
  class: "flex justify-center gap-x-2"
}
const _hoisted_6 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "12",
  height: "12",
  viewBox: "0 0 24 24",
  fill: "none",
  stroke: "currentColor",
  "stroke-width": "2",
  "stroke-linecap": "round",
  "stroke-linejoin": "round"
}
const _hoisted_7 = {
  key: 0,
  points: "5 3 19 12 5 21 5 3"
}
const _hoisted_8 = {
  key: 1,
  x: "6",
  y: "6",
  width: "12",
  height: "12"
}
const _hoisted_9 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isUploadingAudio)
      ? (_openBlock(), _createBlock(_component_Spinner, {
          key: 0,
          class: "w-5 h-5"
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("label", {
            for: _ctx.inputId,
            class: "cursor-pointer"
          }, _cache[4] || (_cache[4] = [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "audio",
              class: "min-w-5 h-5"
            }, null, -1)
          ]), 8, _hoisted_3),
          _createElementVNode("input", {
            id: _ctx.inputId,
            type: "file",
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleFileSelection && _ctx.handleFileSelection(...args))),
            accept: "audio/wav,audio/mp3",
            class: "hidden"
          }, null, 40, _hoisted_4),
          (_ctx.audioSource)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("button", {
                  onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleAudio && _ctx.toggleAudio(...args)), ["prevent"])),
                  class: "p-2 bg-green-500 text-white rounded-full"
                }, [
                  (_openBlock(), _createElementBlock("svg", _hoisted_6, [
                    (!_ctx.isPlaying)
                      ? (_openBlock(), _createElementBlock("polygon", _hoisted_7))
                      : (_openBlock(), _createElementBlock("rect", _hoisted_8))
                  ]))
                ]),
                _createElementVNode("button", {
                  onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.deleteAudio && _ctx.deleteAudio(...args)), ["prevent"])),
                  class: "p-2 bg-red-500 text-white rounded-full"
                }, _cache[5] || (_cache[5] = [
                  _createElementVNode("svg", {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "12",
                    height: "12",
                    viewBox: "0 0 24 24",
                    fill: "none",
                    stroke: "currentColor",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }, [
                    _createElementVNode("polyline", { points: "3 6 5 6 21 6" }),
                    _createElementVNode("path", { d: "M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" })
                  ], -1)
                ]))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("audio", {
            ref: "audioPlayer",
            src: _ctx.audioSource,
            onEnded: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isPlaying = false)),
            class: "hidden"
          }, null, 40, _hoisted_9)
        ]))
  ]))
}