<template>
  <Menu as="div" class="relative">
    <!-- Trigger Button -->
    <MenuButton class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 ps-3 rtl:ps-8 ltr:pe-6 text-start cursor-pointer focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm hover:bg-indigo-100">
      <span class="block truncate">
        {{ selectedOptionLabel }}
      </span>
      <span class="absolute inset-y-0 rtl:start-0 ltr:end-0 flex items-center pe-2 rtl:ps-2 pointer-events-none">
        <img src="@/assets/icons/arrow-down-dropdown.svg" alt="Arrow Down" class="w-4 h-4" />
      </span>
    </MenuButton>

    <!-- Dropdown Menu -->
    <transition enter-active-class="transition ease-out duration-100" enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
      <MenuItems class="absolute mt-1 w-full rounded-lg ring-1 ring-gray-300 bg-white shadow-lg z-10 max-h-60 overflow-auto focus:outline-none text-sm">

        <div class="p-2" v-if="!items.length">
          <div class="text-center text-gray-400 p-2">{{ $t('common.no_data') }}</div>
        </div>

        <!-- Search Input -->
        <div class="p-2" v-if="items.length">
          <input v-model="searchQuery" @keydown.stop type="text" :placeholder="$t('common.search')" class="w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500" />
        </div>

        <!-- Options List -->
        <ul ref="optionsList">
          <MenuItem v-for="item in filteredItems" :key="itemValue(item)" as="template" v-slot="{ active }">
            <li @click="selectOption(itemValue(item))" class="cursor-pointer px-4 py-2 hover:bg-indigo-100 text-gray-700" :class="{ 'bg-indigo-100': active || itemValue(item) === modelValue }" :data-id="itemValue(item)">
              {{ itemLabel(item) }}
            </li>
          </MenuItem>
        </ul>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, onUnmounted, PropType } from "vue";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";

export default defineComponent({
  name: "SingleSelectDropdown",
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
  },
  props: {
    modelValue: {
      type: [String, Number] as PropType<string | number>,
      required: true,
    },
    items: {
      type: Array as PropType<any[]>,
      default: () => [],
      required: false,
    },
    itemLabel: {
      type: Function as PropType<(item: any) => string>,
      required: true,
    },
    itemValue: {
      type: Function as PropType<(item: any) => string | number>,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Select an option",
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const dropdownOpen = ref(false);
    const searchQuery = ref("");
    const optionsList = ref<HTMLElement | null>(null);

    // Compute the label for the selected option, showing the placeholder if not selected
    const selectedOptionLabel = computed<string>(() => {
      const selectedOption = props.items.find((item) => props.itemValue(item) === props.modelValue);
      return selectedOption ? props.itemLabel(selectedOption) : props.placeholder;
    });

    // Select an option
    const selectOption = (value: string | number) => {
      emit("update:modelValue", value);
      searchQuery.value = ""; // Reset search query
    };

    // Filter items based on the search query
    const filteredItems = computed<any[]>(() => {
      const query = searchQuery.value.toLowerCase();
      return props.items.filter((item) => props.itemLabel(item).toLowerCase().includes(query));
    });

    // Function to scroll to the selected item
    const scrollToSelectedItem = () => {
      if (optionsList.value) {
        const selectedOption = optionsList.value.querySelector(`[data-id="${props.modelValue}"]`);
        if (selectedOption) {
          selectedOption.scrollIntoView({ block: "nearest" });
        }
      }
    };

    // Close dropdown when clicking outside
    const closeDropdown = () => {
      dropdownOpen.value = false;
    };

    // Add and remove event listeners for outside click detection
    const handleOutsideClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest(".relative")) {
        closeDropdown();
      }
    };

    onMounted(() => {
      document.addEventListener("click", handleOutsideClick);
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleOutsideClick);
    });

    return {
      dropdownOpen,
      searchQuery,
      selectedOptionLabel,
      filteredItems,
      selectOption,
      optionsList,
    };
  },
});
</script>
