function extractFilters(response: any) {
  return {
    platform_id: response.platform_id || {},
    type: response.type || {},
    school_id: response.school_id || {},
    round_id: response.round_id || {},
    grade_id: response.grade_id || {},
    academic_year: response.academic_year || {},
  };
}
export default extractFilters;
