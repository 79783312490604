import { createStore } from "vuex";
import usersModule from "@/store/modules/usersModule";
import platformsModule from "./modules/platformsModule";
import examModule from "@/store/modules/examModule";
import examStudentsModule from "@/store/modules/examStudentsModule";
import currentUserModule from "@/store/modules/currentUserModule";
import schoolsModule from "@/store/modules/schoolsModule";
import reportsListingModule from "./modules/reportsListingModule";

const store = createStore({
  modules: {
    platforms: platformsModule,

    currentUser: currentUserModule,
    usersManagement: usersModule,

    examForm: examModule,
    assignedStudents: examStudentsModule,

    schools: schoolsModule,

    reports: reportsListingModule,
  },
});

export default store;
