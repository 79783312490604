<template>
  <div class="flex flex-wrap gap-4" v-can="'com.ltm.permissions.platforms.view.platform'">
    <Spinner :showLoadingText="false" v-if="isLoading && !hasError" />
    <MessageLayout v-if="hasError && !isLoading" :message="$t('store.error_fetching_data')" />
    <div v-for="platform in platforms" :key="platform.id" class="flex-none" v-if="!isLoading && !hasError">
      <button
        class="ring-1 ring-gray-300 py-2 px-4 rounded-md hover:bg-indigo-100 whitespace-nowrap"
        @click.prevent="notifyPlatformSelected(platform)"
        :class="{
          'bg-indigo-100 ring-2 ring-indigo-400 font-bold pointer-events-none': selectedPlatform && selectedPlatform.id === platform.id,
        }"
      >
        {{ platform.name }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
import Spinner from "./Spinner.vue";
import MessageLayout from "./MessageLayout.vue";
import { Platform } from "@/interfaces/Platform";

// Vuex Store
const store = useStore();

// Emits
const emit = defineEmits<{ (e: "platform-selected", platform: Platform): void }>();

// Computed Properties
const platforms = computed<Platform[]>(() => store.state.platforms.platforms);
const selectedPlatform = computed<Platform | null>(() => store.state.platforms.selectedPlatform);
const isLoading = computed<boolean>(() => store.state.platforms.isLoading);
const hasError = computed<boolean>(() => store.state.platforms.hasError);

// Methods
const notifyPlatformSelected = (platform: Platform) => {
  store.dispatch("platforms/selectPlatform", platform);
  emit("platform-selected", platform);
};

// Fetch Platforms and Preselect
if (!store.state.platforms.isCached) {
  store.dispatch("platforms/fetchPlatforms").then(() => {
    let platformToSelect: Platform | undefined;

    // If platformId is invalid or not provided, default to the cached platform or the first platform
    if (!platformToSelect) {
      platformToSelect = selectedPlatform.value || platforms.value[0];
    }

    if (platformToSelect) {
      notifyPlatformSelected(platformToSelect);
    } else {
      console.warn("No platforms available to select.");
    }
  });
} else {
  // Use cached data directly
  if (selectedPlatform.value) {
    emit("platform-selected", selectedPlatform.value);
  }
}

// Watch for Changes in platformId Prop
</script>
